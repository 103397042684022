
import React from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/sectionvideo.scss";
import { Player, BigPlayButton } from 'video-react';
import "video-react/dist/video-react.css";
import { Trans, I18nextContext } from 'gatsby-plugin-react-i18next';

const Sectionvideo = () => {
  const context = React.useContext(I18nextContext);
  return (
    <div className="build-to-break" lang={context.language}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-7">
            <h2>
              <Trans>Built to</Trans><br></br><Trans>Break Boundaries!</Trans>
            </h2>
            <h6 className="video-content hidden_mobile"><Trans>Do you wonder what makes our product different from others?</Trans></h6>
            <Link to=""></Link>
            <div className="imges_video">



              <Player className="video-video"
                poster="https://lp-video.s3.eu-central-1.amazonaws.com/logo.jpg">
                {/* <source src="https://lp-video.s3.eu-central-1.amazonaws.com/Logipulse-Promo.mp4"
                /> */}

            <source src="https://lp-video.s3.eu-central-1.amazonaws.com/LP-VIDEO.mp4"
                />
                <BigPlayButton position={"center"} />
              </Player>


            </div>


          </div>
          <div className="col-md-6 col-lg-5">
            <h6 className="video-content" lang={context.language}><Trans>Do you wonder what makes our product different from others?</Trans></h6>

            <p className="first" lang={context.language}>
              <Trans>Logipulse redefines the way logistics is managed. Our logistics management software helps to manage your business end-to-end, we also provide powerful tools that help improve profitability and provide better service to your customers.</Trans>
            </p>

            <div className="second" lang={context.language}>
              <Trans>Logipulse is a suite of services that can  be customized to meet your individual requirements.</Trans>
            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

export default Sectionvideo

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`























