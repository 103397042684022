import React, { useState } from "react";
import Photo from "../Photo";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/mobile.scss";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import Portfoliosection from "../common/portfoliosection";
import { Link, Trans, I18nextContext } from "gatsby-plugin-react-i18next";



const Port = ({ children }) => {
  const context = React.useContext(I18nextContext);
  const [id, setid] = useState("")
  const [checkingId, setcheckingId] = useState('');


  const operation = id => {
    
    if (checkingId == id) {
      id = '';
      setid('')

    } else {
      setid(id)
    }
    setcheckingId(id);
  }




  return (
    <div className="layout">
      <main>{children}</main>
      <Portfoliosection />

      <div className="complete-protfolio mbl-hide-medium" lang={context.language}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Port_Heads_contents" lang={context.language}>
                <Trans>A complete</Trans><br></br><Trans>portfolio of solutions</Trans>
              </div>
              <div className="sample_text" lang={context.language}>
                <Trans>It's such a breeze to install and get going with Logipulse!</Trans>
              </div>
            </div>
          </div>

          <div id="main" className="row bg-img">
            <div className="col-md-6">
              <div className="width-section">
                {/* <!-- Tabs nav --> */}

                <div
                  className="nav flex-column nav-pill nav-pills-custom" lang={context.language}
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <div
                    className="nav-link" lang={context.language}
                    id="v-pills-freight-tab"
                    data-mdb-toggle="pill"
                    aria-controls="v-pills-freight"
                    aria-selected="true"
                  >
                    <h5
                      className="main_content"
                      onClick={() => operation("id1")}
                      id="id1" lang={context.language}
                    >
                      <Trans>FREIGHT FORWARDING</Trans>
                    </h5>
                    <p className="main_sub_cont" lang={context.language}>
                      <Trans>Automate and optimize everything from pickup to delivery.</Trans>
                    </p>

                    {id === "id1" ? (
                      <div class="boxes" lang={context.language}>
                        <div className="caption_conts" lang={context.language}>
                          <div className="caption_content_main" lang={context.language}>
                            <Trans>Automate and optimize everything from pickup to delivery.</Trans>
                          </div>
                          <div className="caption_sub_content" lang={context.language}>
                            <Trans>Shipment inquiry, quotation, pick up, delivery, and more that comes under container management can be entered, assigned, or automated from a single Logipulse platform. Making air, road, and ocean freight forwarding business simple.</Trans>

                          </div>

                          <Link to="/product/" className="know_more">
                            <Trans>KNOW MORE</Trans>
                            <ArrowForwardOutlinedIcon className="white-arrow" />
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div
                    className="nav-link" lang={context.language}
                    id="v-pills-warehouse-tab"
                    data-mdb-toggle="pill"
                    aria-controls="v-pills-warehouse"
                    aria-selected="true"
                  >
                    <h5
                      className="main_content"
                      onClick={() => operation("id2")}
                      id="id2" lang={context.language}
                    >
                      <Trans>3PL & WAREHOUSING</Trans>
                    </h5>
                    <p className="main_sub_cont" lang={context.language}>
                      <Trans>Associate and oversee third-party logistics and warehouses.</Trans>
                    </p>

                    {id === "id2" ? (
                      <div class="boxes" lang={context.language}>
                        <div className="caption_conts" lang={context.language}>
                          <div className="caption_content_main" lang={context.language}>
                            <Trans>Associate and oversee third-party logistics and warehouses.</Trans>
                          </div>
                          <div className="caption_sub_content" lang={context.language}>
                            <Trans>Unite the processes and other required data of third-party services and warehouses as a single entity.</Trans>
                            <Trans> Keep the information about your inventory handy with a 360-degree 2D view and utilize the scalable service to make your business expand anytime.</Trans>
                          </div>

                          <Link to="/warehousing/" className="know_more">
                            <Trans>KNOW MORE</Trans>
                            <ArrowForwardOutlinedIcon className="white-arrow" />
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {/* <div
                    className="nav-link" lang={context.language}
                    id="v-pills-shipping-tab"
                    data-mdb-toggle="pill"
                    aria-controls="v-pills-shipping"
                    aria-selected="true"
                  >
                    <h5
                      className="main_content" lang={context.language}
                      onClick={() => operation("id3")}
                      id="id3"
                    >
                      <Trans>SHIPPING AGENCY</Trans>
                    </h5>
                    <p className="main_sub_cont" lang={context.language}>
                      <Trans>Complete control over your cargo handling operations.</Trans>
                    </p>
                    {id === "id3" ? (
                      <div class="boxes" lang={context.language}>
                        <div className="caption_conts" lang={context.language} >
                          <div className="caption_content_main" lang={context.language}>
                            <Trans>Complete control over your cargo handling operations.</Trans>
                          </div>
                          <div className="caption_sub_content" lang={context.language}>
                            <Trans>Easy and comprehensive shipping management software to manage and inspect operations such as retrieving vessel information, container arrival, release, and tracking. A fully automated system allowing users to obtain complete details of the cargo handling process fast.</Trans>

                          </div>

                          <Link to="/shipingagency/" className="know_more">
                            <Trans>KNOW MORE</Trans>
                            <ArrowForwardOutlinedIcon className="white-arrow" />
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </div> */}

                  <div
                    className="nav-link" lang={context.language}
                    id="v-pills-nvocc-tab"
                    data-mdb-toggle="pill"
                    aria-controls="v-pills-nvocc"
                    aria-selected="false"
                  >
                    <h5
                      className="main_content" lang={context.language}
                      onClick={() => operation("id4")}
                      id="id4"
                    >
                      <Trans>NVOCC</Trans>
                    </h5>
                    <p className="main_sub_cont" lang={context.language}>
                      <Trans>Slot management or Rate management brings just profit.</Trans>
                    </p>
                    {id === "id4" ? (
                      <div class="boxes" lang={context.language}>
                        <div className="caption_conts" lang={context.language}>
                          <div className="caption_content_main" lang={context.language}>
                            <Trans>Slot management or Rate management brings just profit.</Trans>
                          </div>
                          <div className="caption_sub_content" lang={context.language}>
                            <Trans>Tailored to match the needs of NVOCC globally. Schedule and slot management, Tariff and rating management, Job order management, export/import documentation, Vendor contracts management, accounts payables/receivables, and more included in the module.</Trans>

                          </div>

                          <Link to="/Nvocc/" className="know_more">
                            <Trans>KNOW MORE</Trans>
                            <ArrowForwardOutlinedIcon className="white-arrow" />
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div
                    className="nav-link" lang={context.language}
                    id="v-pills-movers-tab"
                    data-mdb-toggle="pill"
                    aria-controls="v-pills-movers"
                    aria-selected="false"
                  >
                    <h5
                      className="main_content" lang={context.language}
                      onClick={() => operation("id5")}
                      id="id5"
                    >
                      <Trans>MOVERS & RELOCATION</Trans>
                    </h5>
                    <p className="main_sub_cont" lang={context.language}>
                      <Trans>Feature-rich customizable platform to assist relocations worldwide.</Trans>
                    </p>

                    {id === "id5" ? (
                      <div class="boxes" lang={context.language}>
                        <div className="caption_conts" lang={context.language}>
                          <div className="caption_content_main" lang={context.language}>
                            <Trans>Feature-rich customizable platform to assist relocations worldwide.</Trans>
                          </div>
                          <div className="caption_sub_content" lang={context.language}>
                            <Trans>Taking care of relocation requirements from a customer’s enquiry details to delivery. Paperless operations automate and integrate multiple functions, from the survey, packing, shipping, billing, accounting, vendor management, and storage into one well-organized platform.</Trans>

                          </div>

                          <Link to="/#/" className="know_more">
                            <Trans>KNOW MORE</Trans>
                            <ArrowForwardOutlinedIcon className="white-arrow" />
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </div>
{/* 
                  <div
                    className="nav-link" lang={context.language}
                    id="v-pills-brocking-tab"
                    data-mdb-toggle="pill"
                    aria-controls="v-pills-brocking"
                    aria-selected="false"
                  >
                    <h5
                      className="main_content" lang={context.language}
                      onClick={() => operation("id6")}
                      id="id6"
                    >
                      <Trans>BROKERING</Trans>
                    </h5>
                    <p className="main_sub_cont" lang={context.language}>
                      <Trans>Gain momentum in acquiring and supplying vessel information.</Trans>{" "}
                    </p>
                    {id === "id6" ? (
                      <div class="boxes" lang={context.language}>
                        <div className="caption_conts" lang={context.language}>
                          <div className="caption_content_main" lang={context.language}>
                            <Trans> Gain momentum in acquiring and supplying vessel information.</Trans>
                          </div>
                          <div className="caption_sub_content" lang={context.language}>
                            <Trans>Know the arrivals and release of vessels instantly and easily. Information about containers, dates of sailing, and more for a broker are incorporated into  the product to make the tasks of Port Brokers’ jobs easy. Be tech-smart, let the software do the work for you.</Trans>

                          </div>

                          <Link to="/Brockering/" className="know_more">
                            <Trans>KNOW MORE</Trans>
                            <ArrowForwardOutlinedIcon className="white-arrow" />
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </div> */}

                  <div
                    className="nav-link" lang={context.language}
                    id="v-pills-vessel-tab"
                    data-mdb-toggle="pill"
                    aria-controls="v-pills-vessel"
                    aria-selected="false"
                  >
                    <h5
                      className="main_content" lang={context.language}
                      onClick={() => operation("id7")}
                      id="id7"
                    >
                      <Trans>CUSTOMS CLEARANCE</Trans>
                    </h5>
                    <p className="main_sub_cont" lang={context.language}>
                      <Trans>Import or export, make your customer’s forwarding constant.</Trans>
                    </p>
                    {id === "id7" ? (
                      <div class="boxes" lang={context.language}>
                        <div className="caption_conts" lang={context.language}>
                          <div className="caption_content_main" lang={context.language}>
                            <Trans>Import or export, make your customer’s forwarding constant.</Trans>
                          </div>
                          <div className="caption_sub_content" lang={context.language}>
                            <Trans>A stage that could make the whole logistics process come to a halt, but not anymore! With Logipulse Customs Clearance you will be empowered to know the vessel information, settle clearance documentation and government formalities, etc.</Trans>

                          </div>

                          <Link to="/CustomClearance/" className="know_more">
                            <Trans>KNOW MORE</Trans>
                            <ArrowForwardOutlinedIcon className="white-arrow" />
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="post" lang={context.language}>
                    <Photo
                      src="air-freight-forwarding-1-1.png-line-1.svg"
                      className="dotted-line1"
                      alt=""
                    />

                    <Photo
                      src="air-freight-forwarding-1-1mob.png"
                      className="mbl_aeroplane"
                      alt="air-freight-forwarding"
                    />

                    <Photo
                      src="freight-forwarding-imports-exports.png"
                      className="mbl_ship"
                      alt="ocean-freight-forwarding"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="overlay"></div> */}

          <div className="row">
            <div className="col-sm-12">
              <p className="last-p-content" lang={context.language}>
                <Trans>An easy workflow that lets you work with shipments and freight transportation,</Trans><br></br>
                <Trans>right from creating leads and quotations for customers, to tracking the shipment of each item.</Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Port
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

