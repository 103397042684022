import React from "react"
import Header from "../../pages/header"
import Section from "../common/sectionvideo"
import Port from "../common/port"
import Powerful from "../common/powerfulsection"
import Crossplatform from "../common/crossplatform"
import Footer from "../common/footer"
import Helmet from "react-helmet"
import "../../styles/layout.scss"
import CookieConsent, { Cookies } from "react-cookie-consent"
import {Link,Trans,useTranslation,I18nextContext,} from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const Layout = ({ children }) => {
  const { t } = useTranslation()
  // const { language } = React.useContext(I18nextContext)
  const context = React.useContext(I18nextContext);
  return (
    <div className="layout layout_full_width" id="layout">
      <Helmet title="Logipulse"></Helmet>
      <Header />
      <main>{children}</main>
      <Section />
      <Crossplatform />
      <Port />
      <Powerful />
      {/* <Photos /> */}

      <Footer />
      <CookieConsent lang={context.language}
        location="bottom"
        overlay
        buttonText= {t("I AGREE")}
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: "#0498e0", color:"#fff", padding:"24px"}}
        buttonStyle={{ background: "#fff", color: "#1a2e54", fontSize: "14px", borderRadius:"8px", width:"120px", height:"40px", fontSize:"12px"}} 
        enableDeclineButton
        declineButtonText={t("DECLINE")}
        declineButtonStyle={{background:"none", border:"2px solid #fff", borderRadius:"8px", height:"40px", fontSize:"14px"}}
        onAccept={() => {
          Cookies.set('gatsby-gdpr-google-analytics', true)
        }}
      >
        <div lang={context.language}><Trans>Cookies help us deliver the best experience on our website. By using our website, you agree to the use of cookies.</Trans></div>
      </CookieConsent>
    </div>
  )
}
export default Layout

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`