import React from 'react';
import Photo from '../Photo';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/portfoliosection.scss";
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import { Trans, useTranslation, I18nextContext, Link } from 'gatsby-plugin-react-i18next';
import { Helmet } from "react-helmet";

const Portfoliosection = () => {
    const context = React.useContext(I18nextContext);

    const { t } = useTranslation();
    const showContent = (id, tabid) => {

        var element = document.getElementById(id);
        var parentElem = document.getElementById("content_parent");
        var childElems = parentElem.childNodes;
        var tabparentElem = document.getElementById("v-pills-tab");
        var childtabElem = tabparentElem.childNodes;


        for (var i = 0; i < childElems.length; i++) {

            if (childElems[i].classList.contains("display_block")) {
                childElems[i].classList.remove("display_block");
                childElems[i].classList.add("display_none");
            }
        }

        if (element.classList.contains("display_none")) {
            element.classList.remove("display_none");
            element.classList.add("display_block");
        }


        for (var j = 0; j < childtabElem.length; j++) {
            if (childtabElem[j].classList.contains("active")) {
                childtabElem[j].classList.remove("active");
            }

        }

        document.getElementById(tabid).classList.add('active')

    }

    return (
            <div className="complete-protfolio large-display-view" lang={context.language}>
                  <Helmet>

                  </Helmet>
                <div className="container">

                    <div className="row">
                        <div className="col-md-6 d-none d-md-block">
                        </div>
                        <div className="col-md-6">
                            <div className="content">

                            </div>

                        </div>
                        <div className="col-md-12">
                            <h2><Trans>A complete</Trans><br></br><Trans>portfolio of logistics solutions</Trans></h2>
                            <h6 className="sample_text"><Trans>It's such a breeze to install and get going with Logipulse!</Trans></h6>
                        </div>
                    </div>



                    <div className="row bg-img" lang={context.language}>
                        <div className="col-md-6 col-lg-5 offset-lg-1 pl-0 pr-0 pl-md-3 pr-md-3" lang={context.language}>
                            <div className="width-sec">

                                {/* <!-- Tabs nav --> */}

                                <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                                    aria-orientation="vertical" lang={context.language}>

                                    <div className="nav-link active" id="v-pills-freight-tab" data-mdb-toggle="pill"
                                        aria-controls="v-pills-freight" aria-selected="true" onClick={() => showContent('v-pills-freight', 'v-pills-freight-tab')}>

                                        <h5 className="main_content" lang={context.language}><Trans>FREIGHT FORWARDING</Trans></h5>
                                        <p className="main_sub_cont" style={{ cursor: "pointer" }}><Trans>Automate and optimize everything from pickup to delivery.</Trans></p>


                                    </div>



                                    <div className="nav-link " id="v-pills-warehouse-tab" data-mdb-toggle="pill"
                                        aria-controls="v-pills-warehouse" aria-selected="true" onClick={() => showContent('v-pills-warehouse', 'v-pills-warehouse-tab')}>

                                        <h5 className="main_content" lang={context.language}><Trans>3PL & WAREHOUSING</Trans></h5>
                                        <p className="main_sub_cont" style={{ cursor: "pointer" }}><Trans>Associate and oversee third party logistics and warehouses.</Trans></p>
                                    </div>

                                    <div className="nav-link" id="v-pills-nvocc-tab" data-mdb-toggle="pill"
                                        aria-controls="v-pills-nvocc" aria-selected="false" onClick={() => showContent('v-pills-nvocc', 'v-pills-nvocc-tab')}>
                                        <h5 className="main_content" lang={context.language}><Trans>NVOCC</Trans></h5>
                                        <p className="main_sub_cont" style={{ cursor: "pointer" }}><Trans>Slot management or Rate management brings just profit.</Trans>
                                        </p>

                                    </div>



                                    <div className="nav-link" id="v-pills-movers-tab" data-mdb-toggle="pill"
                                        aria-controls="v-pills-movers" aria-selected="false" onClick={() => showContent('v-pills-movers', 'v-pills-movers-tab')} >
                                        <h5 className="main_content" lang={context.language}><Trans>MOVERS & RELOCATION</Trans></h5>
                                        <p className="main_sub_cont" style={{ cursor: "pointer" }}><Trans>Feature-rich customizable platform to assist relocations worldwide.</Trans>
                                        </p>

                                    </div>
                                    <div className="nav-link" id="v-pills-vessel-tab" data-mdb-toggle="pill"
                                        aria-controls="v-pills-vessel" aria-selected="false" onClick={() => showContent('v-pills-vessel', 'v-pills-vessel-tab')}>

                                        <h5 className="main_content" lang={context.language}><Trans>CUSTOMS CLEARANCE</Trans></h5>
                                        <p className="main_sub_cont" style={{ cursor: "pointer" }}><Trans>Import or export, make your customer’s forwarding constant.</Trans></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-5 pl-0 pr-0 pl-md-3 pr-md-3 main-tabs-contents" lang={context.language}>

                            {/* <!-- Tabs content --> */}

                            <div className="tab-content" id="v-pills-tabContent" lang={context.language}>
                                <div id="content_parent">
                                    <div className="display_block" id="v-pills-freight" role="tabpanel"
                                        aria-labelledby="v-pills-freight-tab" >

                                        <h3 className="headings" lang={context.language}><Trans>Freight Forwarding</Trans></h3>
                                        <div className="caption_content"><Trans>Automate and optimize everything from pickup to delivery.</Trans></div>
                                        <p className="caption_sub_content"><Trans>Shipment inquiry, quotation, pick up, delivery, and more that comes under container management can be entered, assigned, or automated from a single Logipulse platform. Making air, road, and ocean freight forwarding business simple.</Trans>
                                        </p>

                                        <div className="readmore" lang={context.language}><Link to="/product/freight-forwarding-sofware/" onClick={() => localStorage.setItem("productpagedata", "air")} target="_blank"><Trans>KNOW MORE</Trans>
                                            <ArrowForwardOutlinedIcon className="white-arrow" />
                                        </Link></div>
                                    </div>



                                    <div className="display_none" id="v-pills-warehouse" role="tabpanel"
                                        aria-labelledby="v-pills-warehouse-tab" lang={context.language}>

                                        <h3 className="headings" lang={context.language}><Trans>3PL & Warehousing</Trans></h3>
                                        <div className="caption_content"><Trans>Associate and oversee third-party logistics and warehouses.</Trans></div>
                                        <p className="caption_sub_content"><Trans>Unite the processes and other required data of third-party services and warehouses as a single entity.</Trans>
                                            <Trans>Keep the information about your inventory handy with a 360-degree 2D view and utilize the scalable service to make your business expand anytime.</Trans>
                                        </p>

                                        <div className="readmore" lang={context.language}><Link to="/product/warehouse-management-software/" target="_blank"><Trans>KNOW MORE</Trans>
                                            <ArrowForwardOutlinedIcon className="white-arrow" />
                                        </Link></div>

                                    </div>
                                    <div className="display_none" id="v-pills-nvocc" role="tabpanel"
                                        aria-labelledby="v-pills-nvocc-tab" lang={context.language}>
                                        <h3 className="headings" lang={context.language}><Trans>NVOCC</Trans></h3>
                                        <div className="caption_content"><Trans>Slot management or Rate management brings just profit.</Trans></div>
                                        <p className="caption_sub_content"><Trans>Tailored to match the needs of NVOCC globally.</Trans>
                                           <Trans>Schedule and slot management, tariff and rating management, job order management, export/import documentation, vendor contracts management, accounts payables/receivables, and more included in the module.</Trans>

                                        </p>
                                        <div className="readmore" lang={context.language}><Link to="/product/nvocc-software" target="_blank"><Trans>KNOW MORE</Trans>
                                            <ArrowForwardOutlinedIcon className="white-arrow" />
                                        </Link></div>
                                    </div>

                                    <div className="display_none" id="v-pills-movers" role="tabpanel"
                                        aria-labelledby="v-pills-movers-tab" lang={context.language}>
                                        <h3 className="headings" lang={context.language}><Trans>Movers & Relocation</Trans></h3>
                                        <div className="caption_content"><Trans>Feature-rich customizable platform to assist relocations worldwide.</Trans></div>
                                        <p className="caption_sub_content"><Trans>Taking care of relocation requirements from a customer’s enquiry details to delivery. Paperless operations automate and integrate multiple functions, from the survey, packing, shipping, billing, accounting, vendor management, and storage into one well-organized platform.</Trans>
                                        </p>
                                        <div className="readmore" lang={context.language}><Link to="/features/" target="_blank"> <Trans>KNOW MORE</Trans>
                                            <ArrowForwardOutlinedIcon className="white-arrow" />
                                        </Link></div>
                                    </div>
                                    <div className="display_none" id="v-pills-vessel" role="tabpanel"
                                        aria-labelledby="v-pills-vessel-tab" lang={context.language} >
                                        <h3 className="headings" lang={context.language}><Trans>Customs Clearance</Trans></h3>
                                        <div className="caption_content"><Trans>Import or export, make your customer’s forwarding constant.</Trans></div>
                                        <p className="caption_sub_content">
                                            <Trans>A stage that could make the whole logistics process come to a halt, but not anymore! With Logipulse Customs Clearance you will be empowered to know the vessel information, settle clearance documentation and government formalities, etc.</Trans>
                                        </p>
                                        <div className="readmore" lang={context.language}><Link to="/product/customs-clearance-software" target="_blank"><Trans>KNOW MORE</Trans>
                                            <ArrowForwardOutlinedIcon className="white-arrow" />
                                        </Link></div>
                                    </div>
                                </div>


                                <div className="overlay"></div>
                                <div className="post" >
                                    <Photo src="air-freight-forwarding-1-1.png-line-1.svg" className="dotted-line1" alt="air-freight-forwarding" />
                                    <Photo src="air-freight-forwarding-1-1mob.png" className="freight" alt="air-freight-forwarding" />
                                    <Photo src="freight-forwarding-imports-exports.png" className="ship" alt="ocean-freight-forwarding" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div classn="col-sm-12">
                            <h6 className="last-p"><Trans>A logistics software with an easy workflow that lets you work with shipments and freight transportation,</Trans><br></br>
                                <Trans>right from creating leads and quotations for customers, to tracking the shipment of each item.</Trans>
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="pesduoclass after"></div>
            </div>
     
    )

}

export default Portfoliosection;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`