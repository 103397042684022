import React, { Component } from "react";
import "../../styles/powerfulsection.scss";
import Photo from "../Photo";
import { Link, Trans, I18nextContext } from "gatsby-plugin-react-i18next";
import { withTranslation } from 'react-i18next';
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";


class powerfulsection extends Component {

  static contextType = I18nextContext;
  get_index_of_string(my_array, mystring) {

    /*-------initialize variables---------------*/
    var a = 0;
    var results = [];
    var b = 0, subarray, subarray1;
    var c = 0;
    var found = false;

    for (var arrayIndex in my_array) {
      subarray = my_array[arrayIndex];

      b = 0;
      for (var arrayIndex1 in subarray) {

        subarray1 = subarray[arrayIndex1];
        var search = subarray1.indexOf(mystring)

        if (search != -1) {
          c = search;
          found = true;
          break;
        }

        b++;
      }

      if (found)
        break;

      a++;
    }

    results[0] = a;
    results[1] = b;
    results[2] = c;

    return results;
  }



  constructor() {
    super()
    this.state = {
      name: "React",
    }
    // Binding method
    this.tileArray = this.initArray();
  }

  //expanded the columns // 

  toggleTile(e) {
    var results = this.get_index_of_string(this.tileArray, e.currentTarget.id);
    

    var expanded = this.findPositionOfExpanded(this.tileArray);
    

    var row = results[0];
    var col = results[1];

    if (row < 3) {

      var arrayContainerID = this.arrContainerIDs();
      var item1 = document.getElementById(e.currentTarget.id);
      var containerName = arrayContainerID[expanded[0]][expanded[1]][0];
      var expanedContainer = document.getElementById(containerName);
      var above;
      var below;

      expanedContainer.classList.remove("tile_size_toggler");

      if (row == 1) {
        above = arrayContainerID[results[0] - 1][results[1]][0];
        below = arrayContainerID[results[0] + 1][results[1]][0];
      } else if (row == 0) {
        above = arrayContainerID[results[0] + 1][results[1]][0];
        below = arrayContainerID[results[0] + 2][results[1]][0];
      } else if (row == 2) {
        above = arrayContainerID[results[0] - 1][results[1]][0];
        below = arrayContainerID[results[0] - 2][results[1]][0];
      }

      var moving1 = document.getElementById(above);
      var moving2 = document.getElementById(below);

      var moving1InnerHTML = moving1.innerHTML;
      var moving2InnerHTML = moving2.innerHTML;

      var free1 = this.freeSpace(this.tileArray);
      var freeSpaceName1 = this.tileArray[free1[0]][free1[1]][0];

      var target1 = document.getElementById(freeSpaceName1);
      target1.innerHTML = moving1InnerHTML;
      moving1.innerHTML = '';
      this.tileArray[free1[0]][free1[1]][1] = false;
      this.tileArray[free1[0]][free1[1]][2] = "occupied";

      var free2 = this.freeSpace(this.tileArray);
      var freeSpaceName2 = this.tileArray[free2[0]][free2[1]][0];
      var target2 = document.getElementById(freeSpaceName2);

      target2.innerHTML = moving2InnerHTML;
      moving2.innerHTML = '';
      this.tileArray[free2[0]][free2[1]][1] = false;
      this.tileArray[free2[0]][free2[1]][2] = "occupied";

      target1.style.display = 'flex';
      target2.style.display = 'flex';

      if (row == 1) {
        this.tileArray[results[0] - 1][results[1]][2] = "free";
        this.tileArray[results[0] + 1][results[1]][2] = "free";
      } else if (row == 0) {
        this.tileArray[results[0] + 1][results[1]][2] = "free";
        this.tileArray[results[0] + 2][results[1]][2] = "free";
      } else if (row == 2) {
        this.tileArray[results[0] - 1][results[1]][2] = "free";
        this.tileArray[results[0] - 2][results[1]][2] = "free";
      }

      moving1.style.display = 'none';
      moving2.style.display = 'none';
      item1.classList.add('tile_size_toggler');

      this.tileArray[expanded[0]][expanded[1]][1] = false;
      this.tileArray[results[0]][results[1]][1] = true;
    } else {

      var arrayContainerID = this.arrContainerIDs();
      var item1 = document.getElementById(e.currentTarget.id);
      var containerName = arrayContainerID[expanded[0]][expanded[1]][0];
      var expanedContainer = document.getElementById(containerName);
      var above;
      var below;

      expanedContainer.classList.remove("tile_size_toggler");

      above = arrayContainerID[results[0] - 1][results[1]][0];
      below = arrayContainerID[results[0] - 2][results[1]][0];

      var moving1 = document.getElementById(above);
      var moving2 = document.getElementById(below);

      var moving1InnerHTML = moving1.innerHTML;
      var moving2InnerHTML = moving2.innerHTML;

      var free1 = this.freeSpace(this.tileArray);
      var freeSpaceName1 = this.tileArray[free1[0]][free1[1]][0];

      var target1 = document.getElementById(freeSpaceName1);
      target1.innerHTML = moving1InnerHTML;
      moving1.innerHTML = '';
      this.tileArray[free1[0]][free1[1]][1] = false;
      this.tileArray[free1[0]][free1[1]][2] = "occupied";

      var free2 = this.freeSpace(this.tileArray);
      var freeSpaceName2 = this.tileArray[free2[0]][free2[1]][0];
      var target2 = document.getElementById(freeSpaceName2);

      target2.innerHTML = moving2InnerHTML;
      moving2.innerHTML = '';
      this.tileArray[free2[0]][free2[1]][1] = false;
      this.tileArray[free2[0]][free2[1]][2] = "occupied";

      target1.style.display = 'flex';
      target2.style.display = 'flex';

      this.tileArray[results[0] - 1][results[1]][2] = "free";
      this.tileArray[results[0] - 2][results[1]][2] = "free";

      moving1.style.display = 'none';
      moving2.style.display = 'none';
      item1.classList.add('tile_size_toggler');

      this.tileArray[expanded[0]][expanded[1]][1] = false;
      this.tileArray[results[0]][results[1]][1] = true;

    }

   
  }
  findPositionOfExpanded(my_array) {

    var a = 0;
    var results = [];
    var b = 0, subarray, subarray1;
    var c = 0;
    var found = false;

    for (var arrayIndex in my_array) {
      subarray = my_array[arrayIndex];

      b = 0;
      for (var arrayIndex1 in subarray) {

        subarray1 = subarray[arrayIndex1];
        var search = subarray1.indexOf(true)

        if (search != -1) {
          c = search;
          found = true;
          break;
        }

        b++;
      }

      if (found)
        break;

      a++;
    }

    results[0] = a;
    results[1] = b;
    results[2] = c;

    return results;
  }


  freeSpace(my_array) {

    var a = 0;
    var results = [];
    var b = 0, subarray, subarray1;
    var c = 0;
    var found = false;

    for (var arrayIndex in my_array) {
      subarray = my_array[arrayIndex];

      b = 0;
      for (var arrayIndex1 in subarray) {

        subarray1 = subarray[arrayIndex1];
        var search = subarray1.indexOf("free")

        if (search != -1) {
          c = search;
          found = true;
          break;
        }

        b++;
      }

      if (found)
        break;

      a++;
    }

    results[0] = a;
    results[1] = b;
    results[2] = c;

    return results;
  }

  initArray() {
    var arrTile = [4];
    var i = 0;
    arrTile[0] = [["one", false, 'occupied'], ["five", false, 'occupied'], ["nine", true, 'occupied']];
    arrTile[1] = [["two", false, 'occupied'], ["six", false, 'occupied'], ["ten", false, "free"]];
    arrTile[2] = [["three", false, 'occupied'], ["seven", false, 'occupied'], ["eleven", false, "free"]];
    arrTile[3] = [["four", false, 'occupied'], ["eight", false, 'occupied'], ["tweleve", false, 'occupied']];

    return arrTile;
  }

  arrContainerIDs() {
    var arrTile = [4];
    var i = 0;
    arrTile[0] = [["one", false, 'occupied'], ["five", false, 'occupied'], ["nine", true, 'occupied']];
    arrTile[1] = [["two", false, 'occupied'], ["six", false, 'occupied'], ["ten", false, "free"]];
    arrTile[2] = [["three", false, 'occupied'], ["seven", false, 'occupied'], ["eleven", false, "free"]];
    arrTile[3] = [["four", false, 'occupied'], ["eight", false, 'occupied'], ["tweleve", false, 'occupied']];

    return arrTile;
  }

  render() {
    return (
      <div className="headerup">
           <Helmet>                
               
          </Helmet>
        <div className="">
          <div className="header_content_sections text-right" lang={I18nextContext._currentValue.language}>
            <div className="container">
              <div className="ship_content_section" lang={I18nextContext._currentValue.language}>
                <div className="ship_header" lang={I18nextContext._currentValue.language}>
                  <Trans>A powerful logistics management solution for</Trans><Trans>all new challenges</Trans>
                </div>
                <div className="ship_content" lang={I18nextContext._currentValue.language}>
                  <Trans>The world feels heavy right now;</Trans><br className="mbl_ship_content"></br>
                  <Trans>we’ve put together extra resources to help lighten the load.</Trans>
                </div>
              </div>
            </div>
          </div>

        </div>



        <div className="col-sm-12 upward_wrapper" lang={I18nextContext._currentValue.language}>
          <div className="box-wrapper" lang={I18nextContext._currentValue.language}>
            <div className="shadow-box" id="one" onClick={e => this.toggleTile(e)}>
              <div className="shodow_box_header">
                <div className="ico">
                  <Photo src="translate_black_48dp.svg" alt="" />

                </div>
                <div className="box-text" lang={I18nextContext._currentValue.language}><Trans>Multi Language Interface</Trans></div>
              </div>
              <div className="shadow_box_content_body">
                <div className="shadow_box_content">
                  <Trans>A single language interface will pull you behind in the global market! Logipulse multilingual interface will allow users or branches around the world to use one solution without the barrier of languages.</Trans>



                </div>
                <div className="know_more_icons" lang={I18nextContext._currentValue.language}><Link to="/features/#multilingual"><Trans>Know More</Trans></Link>
                  <Photo
                    src="KnowMore-Right Arrow-H12.svg"
                    className="testing-arrow-icons" lang={I18nextContext._currentValue.language}
                    alt="Multi Language Interface"
                  />
                </div>
              </div>

            </div>


            <div className="shadow-box" id="two" onClick={e => this.toggleTile(e)}>
              <div className="shodow_box_header">
                <div className="ico">
                  <Photo src="fact_check_black_48dp (1).svg" alt="Workflow and Approvals" />
                </div>
                <div className="box-text" lang={I18nextContext._currentValue.language}><Trans>Workflow and Approvals</Trans></div>
              </div>

              <div className="shadow_box_content_body">
                <div className="shadow_box_content">
                  <Trans>Watch your business operations even if you are not around them. You will be able to take and give consent for each process in the cycle. Moreover, customize the workflow as per your specification.</Trans>

                </div>
                <div className="know_more_icons" lang={I18nextContext._currentValue.language}><Link to="/features/#workflowapprovals"><Trans>Know More</Trans></Link>
                  <Photo
                    src="KnowMore-Right Arrow-H12.svg"
                    className="testing-arrow-icons" lang={I18nextContext._currentValue.language}
                    alt="Workflow and Approvals"
                  />
                </div>
              </div>

            </div>

            <div className="shadow-box" id="three" onClick={e => this.toggleTile(e)}  >
              <div className="shodow_box_header">
                <div className="ico">
                  <Photo src="devices_black_48dp.svg" alt="Cross-Platform User-Interface" />
                </div>
                <div className="box-text" lang={I18nextContext._currentValue.language}><Trans>Cross-Platform User-Interface</Trans></div>


              </div>
              <div className="shadow_box_content_body">
                <div className="shadow_box_content">
                  <Trans>Have the benefit of using the software in different modes on different devices. Logipulse can be accessed through browsers or can be used as an app on devices like phones or PDAs and as desktop applications.</Trans>


                </div>
                <div className="know_more_icons" lang={I18nextContext._currentValue.language}><Link to="/features/#Cross-Platform"><Trans>Know More</Trans></Link>
                  <Photo
                    src="KnowMore-Right Arrow-H12.svg"
                    className="testing-arrow-icons" lang={I18nextContext._currentValue.language}
                    alt="Cross-Platform User-Interface"
                  />
                </div>
              </div>

            </div>


            <div className="shadow-box" id="four" onClick={e => this.toggleTile(e)}>
              <div className="shodow_box_header">
                <div className="ico"><Photo src="circle_notifications_black_48dp.svg" alt="Alert Engine" /></div>
                <div className="box-text" lang={I18nextContext._currentValue.language}><Trans>Alert Engine</Trans></div>
              </div>

              <div className="shadow_box_content_body">
                <div className="shadow_box_content">
                  <Trans>Get alerts and notifications on the status of your shipment and always stay one step ahead. Our updates are in real-time thereby alerting you to potential delays.</Trans>

                </div>
                <div className="know_more_icons" lang={I18nextContext._currentValue.language}><Link to="/features/#Alertengine" target="_blank2"><Trans>Know More</Trans></Link>
                  <Photo
                    src="KnowMore-Right Arrow-H12.svg"
                    className="testing-arrow-icons" lang={I18nextContext._currentValue.language}
                    alt="Alert Engine"
                  />
                </div>
              </div>
            </div>


            <div className="shadow-box" id="five" onClick={e => this.toggleTile(e)}>
              <div className="shodow_box_header">
                <div className="ico"><Photo src="space_dashboard_black_48dp.svg" alt="Dashboard with KPI's and BI's" /></div>
                <div className="box-text" lang={I18nextContext._currentValue.language}><Trans>Dashboard with KPI's and BI's</Trans></div>
              </div>
              <div className="shadow_box_content_body">
                <div className="shadow_box_content">
                  <Trans>Get an overview of your business operations by reports and metrics related to your shipments. Learn process statuses with few clicks, and avoid heaps of documents to find regular analyses.</Trans>

                </div>
                <div className="know_more_icons" lang={I18nextContext._currentValue.language}><Link to="/features/#dashboard"><Trans>Know More</Trans></Link>
                  <Photo
                    src="KnowMore-Right Arrow-H12.svg"
                    className="testing-arrow-icons" lang={I18nextContext._currentValue.language}
                    alt="Dashboard with KPI's and BI's"
                  />
                </div>
              </div>
            </div>

            <div className="shadow-box" id="six" onClick={e => this.toggleTile(e)}>
              <div className="shodow_box_header">
                <div className="ico"><Photo src="travel_explore_black_48dp.svg" alt="Track and Trace" /></div>
                <div className="box-text" lang={I18nextContext._currentValue.language}><Trans>Track and Trace</Trans></div>
              </div>
              <div className="shadow_box_content_body">
                <div className="shadow_box_content">
                  <Trans>The unique feature of Logipulse is its Track and Trace which allows one to track a shipment right down to its individual item thereby ensuring complete customer satisfaction. Every component of a shipment is allotted a unique code, allowing for easier tracking in the event of missing items.</Trans>

                </div>
                <div className="know_more_icons" lang={I18nextContext._currentValue.language}><Link to="/features/#TrackTrace"><Trans>Know More</Trans></Link>
                  <Photo
                    src="KnowMore-Right Arrow-H12.svg"
                    className="testing-arrow-icons" lang={I18nextContext._currentValue.language}
                    alt="Track and Trace"
                  />
                </div>
              </div>
            </div>

            <div className="shadow-box" id="seven" onClick={e => this.toggleTile(e)}  >
              <div className="shodow_box_header">
                <div className="ico"><Photo src="inventory_black_48dp.svg" alt="Customizable Report Engine" /></div>
                <div className="box-text" lang={I18nextContext._currentValue.language}><Trans>Customizable Report Engine</Trans></div>
              </div>
              <div className="shadow_box_content_body">
                <div className="shadow_box_content">
                  <Trans>Logipulse customizable report engine enables your clients to create reports with detailed entries as they require. With a basic knowledge of HTML, you can customize each report.</Trans>

                </div>
                <div className="know_more_icons" lang={I18nextContext._currentValue.language}><Link to="/features/#customreport"><Trans>Know More</Trans></Link>

                  <Photo
                    src="KnowMore-Right Arrow-H12.svg"
                    className="testing-arrow-icons" lang={I18nextContext._currentValue.language}
                    alt="Customizable Report Engine"
                  />
                </div>
              </div>
            </div>


            <div className="shadow-box" id="eight" onClick={e => this.toggleTile(e)} >
              <div className="shodow_box_header">
                <div className="ico"><Photo src="auto_awesome_motion_black_48dp.svg" alt="Multiple Tab Viewer" /></div>
                <div className="box-text" lang={I18nextContext._currentValue.language}><Trans>Multiple Tab Viewer</Trans></div>
              </div>
              <div className="shadow_box_content_body">
                <div className="shadow_box_content">
                  <Trans>Multitask using the multiple tab view feature. You can simultaneously handle several activities in different operations effortlessly. Increase productivity, reduce errors, and be flexible using a single device with multiple windows.</Trans>
                </div>
                <div className="know_more_icons" lang={I18nextContext._currentValue.language}><Link to="/features/#multipletab"><Trans>Know More</Trans></Link>
                  <Photo
                    src="KnowMore-Right Arrow-H12.svg"
                    className="testing-arrow-icons" lang={I18nextContext._currentValue.language}
                    alt="Multiple Tab Viewer"
                  />
                </div>
              </div>
            </div>



            <div id="nine" className="shadow-box tile_size_toggler" onClick={e => this.toggleTile(e)}>
              <div className="shodow_box_header">
                <div className="ico"><Photo src="account_tree_black_48dp.svg" alt="Data Sharing with Agencies and Branches" /></div>
                <div className="box-text" lang={I18nextContext._currentValue.language}><Trans>Data Sharing with Agencies and Branches</Trans></div>

              </div>
              <div className="shadow_box_content_body">
                <div className="shadow_box_content">
                  <Trans>With Logipulse you are getting a centralized data storage that is accessible to you, your agencies, and the branches in real-time without a local server or backup facility, or security system.</Trans>

                </div>
                <div className="know_more_icons" lang={I18nextContext._currentValue.language}><Link to="/features/#DataSharing"><Trans>Know More</Trans></Link>
                  <Photo
                    src="KnowMore-Right Arrow-H12.svg"
                    className="testing-arrow-icons"
                    alt="Data Sharing with Agencies and Branches"
                  />
                </div>
              </div>
            </div>



            <div className="shadow-box" id="ten" onClick={e => this.toggleTile(e)}>
              <div className="shodow_box_header">
                <div className="ico"><Photo src="outbound_black_48dp.svg" alt="" /></div>
                <div className="box-text" lang={I18nextContext._currentValue.language}><Link to="/features/"><Trans>And More</Trans></Link></div>
              </div>
            </div>


            <div
              id="eleven"
              className="shadow-box"
              onClick={e => this.toggleTile(e)}
            >
              <div className="ico"></div>
              <div className="box-text" lang={I18nextContext._currentValue.language}><Trans>Eleven</Trans></div>
            </div>


            <div
              className="shadow-box"
              id="connstant"

            >
              <div className="ico"></div>
              <div className="box-text" lang={I18nextContext._currentValue.language}><Link to="/features/"><Trans>And More</Trans></Link></div>
            </div>


          </div>



        </div>

      </div>
    )
  }
}
export default withTranslation()(powerfulsection)

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

