import React from "react"
import Layout from "../components/common/layout"
import "../styles/index.scss"
import {
  Link,
  Trans,
  useTranslation,
  I18nextContext,
} from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet";


const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
const Index = () => {
  const { t } = useTranslation()
  const { language } = React.useContext(I18nextContext)

  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL
      : GATSBY_LANDING_PAGE_URL + `/${language}/`
  const canoncalUrl = buildCanonicalUrl();

  const buildHrefUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
  const hrefUrl = buildHrefUrl();

  return (
    <Layout>
      <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: `
        
<!-- Google Tag Manager -->

        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TK7M58Z');</script>
        
            `,
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `
        <script type="application/ld+json">
{
  "@context": "https://schema.org/",
  "@type": "WebSite",
  "name": "Logipulse",
  "url": "https://www.logipulse.com",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "{search_term_string}",
    "query-input": "required name=search_term_string"
  }
}
</script>
   
        `,
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `
            <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
   
        `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
        
<!-- Google Tag Manager -->

        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TK7M58Z"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>    
            `,
          }}
        />
        <meta charSet="utf-8" />
        <title>
          {t(
            "Logistics Solutions - Freight Forwarding & Logistics Management Software | Logipulse"
          )}
        </title>
        <meta
          name="description"
          content= {t("Logipulse is a world-class Freight Forwarding Software that helps manage shipping, logistics, and inventory operations seamlessly. Manage your air, land, and sea freight easily with our logistics software and transform your business.")}
        />
        <meta
          name="Keywords"
          content={t("freight forwarding software, logistics software, logistics management software, shipping management software")}       
        />
        <Link rel="canonical" href={canoncalUrl} />    
        <Link rel="alternate" href={hrefUrl} hreflang={language} />    
        {/* <meta name="google-site-verification" content="googlee5c71d75cdf877dd"/> */}
      </Helmet>

      <div className="banner">
        <div className="container container-head-style">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="header_content_section text-left" lang={language}>
                <div className="style-design">
                  <div className="freight-forward" lang={language}>
                    <Trans>Freight Forwarding</Trans>
                  </div>
                  <div className="freight-forward-second" lang={language}>
                    <Trans>Automation Software</Trans>
                  </div>
                  <h1 className="freight-forward-third" lang={language}>
                    <Trans>
                      A SaaS based software, making your freight forwarding activities easier, in any language
                    </Trans>
                  </h1>
                  <div className="freight-forward-feature">
                    <div className="freight-trans-one"></div>
                    <div className="freight-trans-two"></div>
                  </div>

                  <div className="trials-parent" lang={language}>
                    <Link
                      className="margin_right"
                      to="/free-trial"
                      lang={language}
                      onClick={() => localStorage.removeItem("userinfo")}
                    >
                      <div
                        className="button-trials button-trials-red"
                        lang={language}
                      >
                        <Trans>GET A FREE TRIAL</Trans>
                      </div>
                    </Link>

                    <Link
                      style={{ textDecoration: "inherit" }}
                      to="/purchase/"
                      lang={language}
                      onClick={() => localStorage.removeItem("userinfo")}
                    >
                      <div
                        className="button-trials button-trials-blue"
                        lang={language}
                      >
                        <Trans>BUY LICENSE</Trans>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Index

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
