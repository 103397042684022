import React from 'react';
import Photo from '../Photo';
import "../../styles/crossplatform.scss";
import { Trans, I18nextContext } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const Crossplatform = () => {
  const context = React.useContext(I18nextContext);

  return (
    <div>

      <div className="crossplatform" lang={context.language}>
        <div className="container">
          <div className="row arabictranslate" lang={context.language}>



            <div className="col-sm-6">
              <div className="samplerows">
                <div className="Sample_contents" lang={context.language}><Trans>Cross-platform, SaaS and</Trans><br></br><Trans>cloud-based apps for smart devices.</Trans></div>
                <div className="Sub_main_heads">
                  <Trans>Logipulse provides a leading-edge SaaS logistics solution to advance in every logistics activity with a design that responds efficiently to devices such as Desktop, Tablet, Mobile, PDA, Scanning Devices, etc. Helping you do business even while being on the move.</Trans></div>
              </div>
            </div>

            <div className="col-sm-6">

              <Photo
                src="powerful-bottom-right.png"
                className="secondimages" 
                alt="logipulse_Dashboard"
              />

            </div>


          </div>
        </div>

      </div>
    </div>
  )
}

export default Crossplatform


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`